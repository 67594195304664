import "./about.css";
import ReactHtmlParser from "react-html-parser";

import { Slide } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";

const data = [
  {
    title: "Unleashing <br> Boundless <br> Creativity",
    caption:
      "Our creations go beyond the ordinary, inspiring curiosity and igniting imagination. Meticulously crafted environments that reveal limitless possibilities.",
    img: "/image/about/a.png",
  },
  {
    title: "Harnessing <br> Human <br> Emotion",
    caption:
      "Our curated spaces inspire tranquility, excitement, and wonder. With a careful blend of light, color, and texture, we leave an enduring imprint on the hearts of our visitors.",
    img: "/image/about/b.png",
  },
  {
    title: "Crafting <br> Memorable <br> Experiences",
    caption:
      "Our spaces guide and enhance journeys, seamlessly accommodating needs and desires. With meticulous attention to flow, functionality, and purpose, each encounter becomes an opportunity for inspiration and connection.",
    img: "/image/about/c.png",
  },
];

export default function About() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <section id="about">
      {isTabletOrMobile && (
        <div className="w-full">
          <div className="flex flex-col gap-3 justify-center w-full align-middle mx-auto items-center">
            <div className="text-3xl mt-5 text-center">
              <h5 className="text-[#FFFFFF]">
                Easy. Safe. <br />
                Personalized.
              </h5>
            </div>
            <div className="lg:text-2xl md:text-md text-sm text-center mb-3 w-full">
              <span className="text-[#5B6770]">
                Tailor-made insurance packages that suit your needs. <br /> Take
                our 30-second assessment and find out which plan <br /> works
                best with your travel details and budget.
              </span>
            </div>
            <div>
              <a
                href="#purchase"
                className="apply-button py-2  px-7 h-full items-center text-xs align-middle text-sarabun"
              >
                APPLY NOW
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="relative flex">
        <div className="relative w-full lg:my-10 my-2" id="about_us_height">
          <Slide duration={1000} direction="left" triggerOnce>
            <div className="w-full relative mt-10">
              <div className="ml-[20%] ">
                <h6 className="text-2xl">ABOUT US</h6>
              </div>
              <img
                src="/image/footer/divider.png"
                alt="divider"
                className="md:w-[40%] w-[85%] aboutus-divider"
              />
            </div>
            <div className="w-full relative my-5">
              <div className="ml-[20%] lg:text-2xl md:text-lg text-sm">
                <span className="text-[#5B6770] lg:text-3xl md:text-xl text-md">
                  Safe to Go is an application designed to ease the lives of
                  Korean Visa applicants who are applying for personalized
                  travel insurance.
                </span>
              </div>
            </div>
          </Slide>
        </div>
        <div className="relative w-full">
          {/* <Slide duration={1000} direction="right" > */}
          <img
            src="/image/about/asian-woman.png"
            alt="asian-woman"
            className="lg:max-w-[650px] md:max-w-full max-w-[350px] object-cover lg:top-[-10%] top-[20%] absolute asian-woman lg:right-[10%] right-[15%]"
          />
          {/* </Slide> */}
        </div>
      </div>
    </section>
  );
}
