import { React, useState } from "react";
import SubscriptionForm1 from "../forms/SubscriptionForm2";
// import img from '../../img/services/_CX-Driven-Social-Media-Website-Digital-Services.jpg'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Fade, Bounce, AttentionSeeker } from "react-awesome-reveal";
import { BsGlobe2 } from "react-icons/bs";
import NotifModal from "../modal/NotifModal";
import Image from "../../img/contact/contact3.png";
import "./contact.css";
import "./checkout.scss";
import axios from "axios";
import Footer from "./../Footer";
import ReactHtmlParser from "react-html-parser";

// import { InsurancePosts } from "../posts/InsurancePost";

export default function ContactUs({ close, id, url }) {
  const [notif, setNotif] = useState(false);

  const apiSite = `site`;
  // const handJoinSubmit = (e) => {
  //   e.preventDefault();
  // };

  console.log(url, "checkout url");
  const handleClose = () => {
    close(false);
  };

  const handleApply = () => {
    // checkout(true);
  };

  return (
    <div className="modal" id="modalCheckout">
      <div className="modal-container xl:px-[10px] lg:px-[7px] px-0 py-[30px]">
        <div className="modal-body relative z-20">
          <div className="absolute top-[3%] md:right-[3%] right-[3%] text-center md:max-w-[60px] max-w-[30px]">
            <button className="close-btn" onClick={handleClose}>
              <img
                src="/image/contact/checkout-close.png"
                className="object-contain"
                alt=""
              />
            </button>
          </div>
          <div className="w-full flex flex-col items-center justify-center text-white">
            <div className="xl:my-8 md:mt-[180px] mt-[50px] mb-10 text-center lg:text-5xl md:text-3xl text-xl font-bold">
              <h5>
                YOU'RE ABOUT TO BE <br className="md:hidden block" /> REDIRECTED{" "}
                <br className="md:block hidden" /> TO ANOTHER SITE
              </h5>
            </div>
            <div className="checkout-content lg:w-[80%] w-[90%]">
              <div className="checkout-body flex flex-col justify-center mx-auto h-full w-full">
                <div className="text-center lg:text-3xl md:text-xl text-lg w-full mx-auto my-5">
                  Completion of payment and <br className="xl:hidden block" />{" "}
                  confirmation will be done on{" "}
                  <br className="xl:block hidden" /> our{" "}
                  <br className="xl:hidden block" /> insurance partner's
                  website.
                </div>
                <div className="text-center lg:text-3xl md:text-xl text-lg w-[60%] mx-auto my-5">
                  Click "PROCEED" to continue.
                </div>
                <div className="text-center xl:mt-10 lg:mt-8 mt-5">
                  <a
                    href={url}
                    target="_blank"
                    className={`${
                      url == null ? "disabled" : ""
                    } proceed-button my-5 py-2 lg:px-[50px] px-7 lg:text-3xl md:text-xl text-lg`}
                  >
                    <span>PROCEED</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NotifModal show={notif} dismiss={() => setNotif(false)} />
    </div>
  );
}
