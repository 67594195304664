import { useState, useEffect, React } from "react";
import {
  FaRegEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

import "./footer.css";

import TermModal from "./modal/TermsModal";
import PrivacyModal from "./modal/PrivacyModal";

import { Slide, AttentionSeeker } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";

const socials = [
  {
    title: "facebook",
    img: "/image/footer/socials/fb.png",
    src: "https://www.facebook.com/MoodboardSpace",
  },
  {
    title: "instagram",
    img: "/image/footer/socials/ig.png",
    src: "https://www.instagram.com/moodboard_space/",
  },
];

export default function Footer() {
  const [termModal, setTermModal] = useState();
  const [privacyModal, setPrivacyModal] = useState();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const termClick = () => {
    setTermModal(true);
  };

  const privacyClick = () => {
    setPrivacyModal(true);
  };

  const closeModal = () => {
    setTermModal(false);
    setPrivacyModal(false);
    document.body.style.overflow = "initial";
  };

  return (
    <section className="z-50">
      <footer
        className="relative footer-container w-full flex flex-col items-center"
        id="contact"
      >
        {/* <div className="bg-[#fff7ed] top-[-80%] left-0 h-[300px] w-full absolute form-background -z-10"></div> */}
        <div className="w-full relative mt-10">
          <Slide duration={1000} direction="left" triggerOnce>
            <div className="ml-[10%] text-2xl">
              <h6>CONTACT US</h6>
            </div>
            <img
              src="/image/footer/divider.png"
              alt="divider"
              className="md:w-[30%] w-[55%]"
            />
          </Slide>
        </div>
        <div className="w-full my-5 flex">
          <div className="flex flex-col pl-[10%] gap-5 justify-between my-3 leading-loose">
            <Slide duration={1000} direction="up" triggerOnce>
              <div className="font-semibold lg:text-xl text-lg">
                <span>OFFICE LOCATION</span>
              </div>

              {isTabletOrMobile && (
                <div className="flex gap-3">
                  <img
                    src="/image/footer/socials/location.png"
                    alt="location logo"
                    className="w-5 object-contain"
                  />
                  <div className="flex flex-col lg:text-lg md:text-md text-sm font-medium items-center h-full footer-content">
                    <span className="leading-5">
                      29F & 36F Rufino Pacific Tower, 6784 Ayala Ave.,<br />
                      Legaspi Village, Makati, 1226 Metro Manila
                    </span>
                  </div>
                </div>
              )}
            </Slide>
            <Slide duration={1000} direction="up" triggerOnce>
              <div className="font-semibold lg:text-xl text-lg">
                <span>CONTACT INFORMATION</span>
              </div>
              {isTabletOrMobile && (
                <div className="flex flex-col gap-2">
                  <div className="flex gap-3">
                    <img
                      src="/image/footer/socials/phone.png"
                      alt="location logo"
                      className="w-5 object-contain"
                    />
                    <div className="flex flex-col lg:text-lg md:text-md text-sm font-medium footer-content">
                      <span>+63 2 8811 0388</span>
                    </div>
                  </div>
                  <div className="flex gap-3">
                    <img
                      src="/image/footer/socials/email.png"
                      alt="location logo"
                      className="w-5 object-contain"
                    />
                    <div className="flex flex-col lg:text-lg md:text-md text-sm font-bold">
                      <span className="email-footer">
                        info.philippines@lockton.com <div></div>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </Slide>
          </div>
          <div className="h-inherit mx-5 border text-[#EF5399] md:block hidden"></div>
          {isDesktopOrLaptop && (
            <div className="">
              <Slide duration={1000} direction="up" triggerOnce>
                <div className="flex gap-3">
                  <img
                    src="/image/footer/socials/location.png"
                    alt="location logo"
                    className="w-5 object-contain"
                  />
                  <div className="flex flex-col lg:text-lg text-md">
                    <span>29F & 36F Rufino Pacific Tower, 6784 Ayala Ave.,</span>
                    <span>Legaspi Village, Makati, 1226 Metro Manila</span>
                  </div>
                </div>
                <div className="flex gap-3">
                  <img
                    src="/image/footer/socials/phone.png"
                    alt="location logo"
                    className="w-5 object-contain"
                  />
                  <div className="flex flex-col lg:text-lg text-md">
                    <span>+63 2 8811 0388</span>
                  </div>
                </div>
                <div className="flex gap-3">
                  <img
                    src="/image/footer/socials/email.png"
                    alt="location logo"
                    className="w-5 object-contain"
                  />
                  <div className="flex flex-col lg:text-lg text-md font-bold">
                    <span className="email-footer">
                      info.philippines@lockton.com <div></div>
                    </span>
                  </div>
                </div>
              </Slide>
            </div>
          )}
        </div>
      </footer>
      <div className="bg-[#FFF7ED] w-full">
        <img src="/image/footer/wave.png" alt="wave" className="object-cover" />
      </div>
      {termModal && <TermModal show={termModal} close={closeModal} />}
      {privacyModal && <PrivacyModal show={privacyModal} close={closeModal} />}
    </section>
  );
}
