import Modal from "react-modal";
import { Bounce } from "react-reveal";
import { AiFillCloseCircle } from "react-icons/ai";

const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // minWidth: '90vw',
    // minHeight: '90vh',
    // overflow: 'hidden',
    // background: 'url(img/dialog/background.jpg) center/cover no-repeat',
    // borderRadius: '1em'
    border: "0",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.85)",
    zIndex: 1050,
  },
};
Modal.setAppElement("#root");

export default function NotifModal({ show, dismiss }) {
  return (
    <Modal
      isOpen={show}
      style={customStyles}
      onRequestClose={dismiss}
      // shouldCloseOnOverlayClick={true}
      className="flex justify-center items-center w-full h-full border-none outline-none"
    >
      <div className="sm:w-[325px] w-[90%] block mx-auto relative md:rounded-[1em]">
        <Bounce>
          <div className="bg-[#ef5399] md:p-7 p-3 rounded-[1em]">
            <div className="absolute right-1 top-1 w-6 h-6">
              {/* <button
                className="text-2xl text-[#C2082A] z-20"
                onClick={dismiss}
              >
                <AiFillCloseCircle />
              </button> */}

              <button className="close-btn-modal" onClick={dismiss}>
                <img
                  src="/image/contact/checkout-close.png"
                  className="object-contain"
                  alt=""
                />
              </button>
            </div>
            {/* <div className="block mb-3">
              <img
                src="img/top-section/red-bubble-mark-logo.png"
                className="max-w-[40px]"
                alt="bubble mark"
              />
            </div> */}
            <div className="block">
              <div className="text-sarabun">
                CAPTCHA and acceptance of our Privacy Policy are required to
                continue.
              </div>
            </div>
          </div>
        </Bounce>
      </div>
    </Modal>
  );
}
