import { React, useState, useRef, useEffect } from "react";
import Select, { components, DropdownIndicatorProps } from "react-select";
import makeAnimated from "react-select/animated";
import SubscriptionForm1 from "../forms/SubscriptionForm2";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Fade, Bounce, AttentionSeeker } from "react-awesome-reveal";
import NotifModal from "../modal/NotifModal";
import "./contact.css";
import "./contact.scss";
import axios from "axios";
import Insurance from "./Insurance";
import Checkout from "./Checkout";
import { start } from "nprogress";
import Form from "react-bootstrap/Form";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReCAPTCHA from "react-google-recaptcha";

import { useMediaQuery } from "react-responsive";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src="/image/contact/arrow.png"
        className="w-4 object-contain"
        alt=""
      />
    </components.DropdownIndicator>
  );
};

let ageInitial = [
  {
    value: "0.5",
    label: "6 months old",
  },
  {
    value: "0.583333",
    label: "7 months old",
  },
  {
    value: "0.66667",
    label: "8 months old",
  },
  {
    value: "0.75",
    label: "9 months old",
  },
  {
    value: "0.833333",
    label: "10 months old",
  },
  {
    value: "0.9166667",
    label: "11 months old",
  },

  {
    value: "1",
    label: "1 year old",
  },
];

export default function ContactUs() {
  const [insurance_package, setPackage] = useState([]);
  const [sessionId, setSessionId] = useState();
  const [notif, setNotif] = useState(false);
  const [blurContact, setBlurContact] = useState(false);
  const [blurInsurance, setBlurInsurance] = useState(false);
  const [insurance, setInsurance] = useState(false);
  const [checkout, setCheckout] = useState(false);
  const [checked, setChecked] = useState(false);
  const [age, setAge] = useState(ageInitial);
  const [maxAge, setMaxAge] = useState(0);
  const [duration, setDuration] = useState([]);
  const [url, setUrl] = useState();
  const agreement = useRef();
  const startDate = useRef();
  const endDate = useRef();
  const InsuranceModal = useRef();
  const [capthcaToken, setCaptchaToken] = useState(null);
  let captcha;


  const [formAge, setFormAge] = useState();
  const [formDestination, setFormDestination] = useState();
  const [formDuration, setFormDuration] = useState();
  const [formNature, setFormNature] = useState();
  const [formStartDate, setFormStartDate] = useState(new Date());
  // const [minDate, setMinDate] = useState(new Date().toISOString().split('T')[0]);

  const [durationDropdown, setDurationDropdown] = useState(true);
  const [destinationDropdown, setDestinationDropdown] = useState(true);
  const [natureDropwdown, setNatureDropdown] = useState(true);

  const animatedComponents = makeAnimated();

  const insuranceForm = "insurance";
  const checkoutForm = "checkout";

  const apiSite = `site`;

  //render if mobile or desktop
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  //render end here

  const setCaptchaRef = (ref) => {
    if (ref) {
      return captcha = ref;
    }
 };

 const resetCaptcha = () => {
  // maybe set it till after is submitted
  captcha.reset();
}

  const minDate = new Date()
    .toISOString()
    .split("T")[0]
    .toLocaleString("en", { timeZone: "Asia/Manila" });

  const dropdownAge = () => {
    setAge(ageInitial);
    let initialAge = 2;
    // console.log(initialAge);
    for (initialAge; initialAge < 86; initialAge++) {
      let insertAge = [
        {
          value: initialAge,
          label: initialAge + " years old",
        },
      ];
      setAge((age) => age.concat(insertAge));
      // console.log(initialAge);
    }
  };

  const dropdownDuration = (age) => {
    console.log("drop down here: " + age.value);
    setDuration([]);

    let initialDuration = 1;
    // let maxDuration = 285;
    let maxDuration = 181;

    if (age.value > 75 || age.value < 1) {
      maxDuration = 181;
    }

    for (initialDuration; initialDuration < maxDuration; initialDuration++) {
      let days = "";

      initialDuration == 1 ? (days = " day") : (days = " days");

      let insertDuration = [
        {
          value: initialDuration,
          label: initialDuration + days,
        },
      ];
      setDuration((duration) => duration.concat(insertDuration));
    }
  };

  useEffect(() => {
    // console.log(activeIndex);
    dropdownAge();
  }, []);

  const NoT = [
    {
      value: "leisure",
      label: "Leisure",
    },
    {
      value: "education_training",
      label: "Education/Training",
    },
    {
      value: "work_business_meeting_conference",
      label: "Work/Business/Meeting/Conference",
    },
  ];

  const destination = [
    {
      value: "incheon_south_korea",
      label: "Incheon, South Korea",
    },
  ];

  const handJoinSubmit = (e) => {
    e.preventDefault();
    const agreement = document.getElementById("agreement-form");
    // const captchaValue = recaptcha.current.getValue();

    // console.log(captchaValue, "captcha value");

    if (!agreement.classList.contains("active") || capthcaToken == null) {
      setNotif(true);
    } else {
      setCaptchaToken(null);
      const activeInsurance = document.getElementById("purchase");
      // activeInsurance.style.height = "500px";
      console.log(formStartDate, "form button");

      // const contact = document.getElementById("blur_contact");
      // contact.classList.add("blur-contact");

      const formData = new FormData();
      formData.append("age", formAge.value);
      formData.append("destination", formDestination.value);
      formData.append("duration_of_stay", formDuration.value);
      formData.append("nature_of_travel", formNature.value);
      formData.append("start_date", getFormattedDate(formStartDate));
      formData.append("end_date", endDate.current.value);
      const apiSite = `${process.env.REACT_APP_API_ENDPOINT}/api/form-submit`;
      // const apiSite = "https://kvac-api.binary.digital/api/form-submit";
      axios.post(apiSite, formData).then((response) => {
        if (response.status === 200) {
          console.log(response.data, "reponse data");
          let id = response.data.data.id;
          let packageInsurance = response.data.package;
          setSessionId(id);
          console.log("session id: " + id);
          setBlurContact(true);
          setInsurance(true);
          localStorage.setItem("sessionId", id);
          setPackage(packageInsurance);
          // InsuranceModal.current.focus();
          // activeInsurance.style.height = "500px";
          document.body.style.overflowY = "hidden";
        } else {
          console.log("something went wrong");
        }
      });
    }
  };

  const handleCheck = () => {
    console.log("here");
    const agreement = document.getElementById("agreement-form");

    !agreement.classList.contains("active")
      ? (agreement.className += " active")
      : agreement.classList.remove("active");
  };

  const handleFormAge = (age) => {
    setFormAge(age);
    setDestinationDropdown(false);
    dropdownDuration(age);
  };

  const handleFormDuration = (duration) => {
    setFormDuration(duration);
    setNatureDropdown(false);

    let pickedDate = new Date(formStartDate);
    let finalDate = new Date();

    console.log(formStartDate, "eto date value");

    pickedDate.setDate(pickedDate.getDate() + (duration.value - 1));
    pickedDate.setHours(0, 0, 0, 0);

    endDate.current.value = getFormattedDate(pickedDate);

    console.log(pickedDate, "this is it");
  };

  function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "/" + day + "/" + year;
  }

  const handleFormDestination = (destination) => {
    setFormDestination(destination);
    setDurationDropdown(false);
  };

  const handleFormNature = (nature) => {
    setFormNature(nature);
  };

  const handleChangeStartDate = (e) => {
    // console.log(e, "dito nagsimula")
    setFormStartDate(e);
    setFormDuration(0);
    endDate.current.value = "";
  };

  const handleCheckoutOpen = () => {
    setCheckout(true);
    setInsurance(false);
  };

  const handleCheckoutClose = () => {
    setInsurance(true);
    setCheckout(false);
  };

  const handleDurationClick = (e) => {
    // console.log('eto nag propromt');
    if (formAge == null) {
      const submit = document.getElementById("submit_button");
      const age = document.getElementById("age_form");
      age.focus();
      submit.click();
      console.log(duration);
    }
  };

  const handleFocus = (e: ReactClickEvent<HTMLInputElement>) => {
    const { target } = e;

    if (target) {
      target.readOnly = true; // -------> this for all others
      target.blur(); //  ------> this for ios iphone, TV Browsers, Ipad, Safari
    }
  };

  // const handleChangeRecaptcha = (grecaptcha) => {
  //   window.grecaptcha.reset();
  // }
  return (
    <>
      <section
        className="relative z-20 contact-kvac xl:p-[70px] md:p-[40px] p-[20px]"
        id="purchase"
      >
        <div className="w-full flex flex-col items-center justify-center text-white">
          <div className="lg:text-5xl md:text-3xl sm:text-xl text-lg font-medium">
            <h5>FIND THE BEST PACKAGE FOR YOU</h5>
          </div>
          <div className="text-center md:my-10 my-3 lg:text-xl md:text-md sm:text-sm text-xs">
            <span>
              Fill out this assesment form so we can determine the{" "}
              <br className="md:hidden block" /> best package{" "}
              <br className="md:block hidden" />
              that suits your travel needs.
            </span>
          </div>
        </div>
        <div className="form-container flex xl:flex-nowrap flex-wrap relative h-full w-full xl:justify-start justify-center">
          {/* desktop */}
          {isDesktopOrLaptop && (
            <div className="w-[44%] image-contact relative">
              <div className="relative w-full">
                <img
                  src="/image/contact/desktop-image1.jpg"
                  className="object-cover w-full"
                  alt="form image"
                />
              </div>
              {/* <Fade duration={1000} delay={500}> */}
                <div className="absolute z-10 top-[10%] left-[10%] right-[10%]">
                  <div className="flex flex-wrap lg:flex-col gap-3 lg:w-full md:w-full mx-auto step-form ">
                    <div className="flex flex-row gap-2 step w-full">
                      <div className="step-item lg:text-xl md:text-lg text-md">
                        STEP 1:
                      </div>
                      <div className="step-item-desc lg:text-xl md:text-md text-sm">
                        Fill in the required information
                      </div>
                    </div>
                    <div className="flex flex-row gap-2 step w-full">
                      <div className="step-item lg:text-xl md:text-lg text-md">
                        STEP 2:
                      </div>
                      <div className="step-item-desc lg:text-xl md:text-md text-sm">
                        Pick your preferred package
                      </div>
                    </div>
                    <div className="flex flex-row gap-2 step w-full">
                      <div className="step-item lg:text-xl md:text-lg text-md">
                        STEP 3:
                      </div>
                      <div className="step-item-desc lg:text-xl md:text-md text-sm">
                        Confirm your selection
                      </div>
                    </div>
                    <div className="flex flex-row gap-2 step w-full">
                      <div className="step-item lg:text-xl md:text-lg text-md">
                        STEP 4:
                      </div>
                      <div className="step-item-desc lg:text-xl md:text-md text-sm">
                        Submit and you're Safe to Go
                      </div>
                    </div>
                  </div>
                </div>
              {/* </Fade> */}
            </div>
          )}

          {/* mobile  */}
          {isTabletOrMobile && (
            <div className="relative w-full form-container">
              <img
                src="/image/contact/mobile-image2.jpg"
                className="object-cover w-full mobile-image h-[190px]"
                alt="form image"
              />
              {/* <Fade duration={1000} delay={500}> */}
                <div className="absolute z-10 xl:top-[27%] lg:top-[25%] top-[18%] w-full place-items-center">
                  <div className="flex sm:grid grid-cols-12 flex-wrap gap-3 lg:mx-10 sm:mx-3 mx-auto sm:w-[100%] w-[78%] h-full items-center align-middle">
                    <div className="flex flex-row gap-1 step w-full col-span-6">
                      <div className="step-item md:text-lg text-[13px]">
                        STEP 1:
                      </div>
                      <div className="step-item-desc md:text-lg text-[12px]">
                        Fill in the required information
                      </div>
                    </div>
                    <div className="flex flex-row gap-1 step w-full col-span-6">
                      <div className="step-item md:text-lg text-[13px]">
                        STEP 2:
                      </div>
                      <div className="step-item-desc md:text-lg text-[12px]">
                        Pick your preferred package
                      </div>
                    </div>
                    <div className="flex flex-row gap-1 step w-full col-span-6">
                      <div className="step-item md:text-lg text-[13px]">
                        STEP 3:
                      </div>
                      <div className="step-item-desc md:text-lg text-[12px]">
                        Confirm your selection
                      </div>
                    </div>
                    <div className="flex flex-row gap-1 step w-full col-span-6">
                      <div className="step-item md:text-lg text-[13px]">
                        STEP 4:
                      </div>
                      <div className="step-item-desc md:text-lg text-[12px]">
                        Submit and you're Safe to Go
                      </div>
                    </div>
                  </div>
                </div>
              {/* </Fade> */}
            </div>
          )}

          {/* offset */}
          {isDesktopOrLaptop && (
            <div className="w-[50.6%] h-full image-contact-1 relative">
              <div className="relative w-full">
                <img
                  src="/image/contact/desktop-image1.jpg"
                  className="object-cover w-full opacity-0 -z-10"
                  alt="form image"
                />
              </div>
            </div>
          )}

          <form
            action=""
            className="form-content w-full xl:p-8 lg:p-6 md:p-1 p-0"
            onSubmit={handJoinSubmit}
            id="contact_form"
          >
            <div className="p-5 flex flex-wrap justify-center items-center h-full form-body">
              <div className="w-full flex flex-wrap gap-2">
                <div className="flex w-full xl:flex-row flex-col xl:gap-10 gap-3">
                  <div className="flex flex-col gap-2 kvac-input xl:w-[50%] w-full mx-auto">
                    <label
                      htmlFor=""
                      className="lg:text-xl md:text-lg text-md font-medium"
                    >
                      Age <span className="text-[#ef5399]">*</span>
                    </label>
                    <Select
                      options={age}
                      components={{ DropdownIndicator }}
                      className="select-container w-full"
                      classNamePrefix="select-item"
                      placeholder={false}
                      id="age_form"
                      required
                      onChange={(ageMax) => handleFormAge(ageMax)}
                      value={formAge}
                    />
                  </div>
                  <div className="flex flex-col gap-2 kvac-input xl:w-[50%] w-full mx-auto">
                    <label
                      htmlFor=""
                      className="lg:text-xl md:text-lg text-md font-medium"
                    >
                      Destination <span className="text-[#ef5399]">*</span>
                    </label>
                    <Select
                      options={destination}
                      components={{ DropdownIndicator }}
                      id="destination_form"
                      className="select-container w-full"
                      classNamePrefix="select-item"
                      placeholder={false}
                      required
                      // isDisabled={durationDropdown}
                      onChange={(destination) =>
                        handleFormDestination(destination)
                      }
                      value={formDestination}
                    />
                  </div>
                </div>
                <div className="flex w-full xl:flex-row flex-col xl:gap-10 gap-3">
                  <div className="flex flex-col gap-2 kvac-input xl:w-[50%] w-full mx-auto">
                    <label
                      htmlFor=""
                      className="lg:text-xl md:text-lg text-md font-medium"
                    >
                      Start Date <span className="text-[#ef5399]">*</span>
                    </label>
                    {/* <input
                      ref={startDate}
                      min={minDate}
                      type="date"
                      name="startDate"
                      id="startDate"
                      className="pl-2 select-container w-full bg-[#F5F5F5]"
                      onChange={handleChangeStartDate}
                    /> */}
                    {/* <Form.Control
                      ref={startDate}
                      min={minDate}
                      type="date"
                      name="startDate"
                      id="startDate"
                      className="pl-2 select-container w-full bg-[#F5F5F5]"
                      onChange={handleChangeStartDate}
                    /> */}

                    <DatePicker
                      ref={startDate}
                      minDate={minDate}
                      name="startDate"
                      id="startDate"
                      selected={formStartDate}
                      className="pl-2 select-container w-full bg-[#F5F5F5] z-40"
                      onChange={(e) => handleChangeStartDate(e)}
                      required={true}
                      autoComplete={false}
                      disabledKeyboardNavigation={false}
                      onFocus={handleFocus}
                    />
                  </div>
                  <div className="flex flex-col gap-2 kvac-input xl:w-[50%] w-full mx-auto">
                    <label
                      htmlFor=""
                      className="lg:text-xl md:text-lg text-md font-medium"
                    >
                      Duration of Stay <span className="text-[#ef5399]">*</span>
                    </label>
                    <Select
                      options={duration}
                      components={{ DropdownIndicator }}
                      id="duration_stay_form"
                      className="select-container w-full"
                      classNamePrefix="select-item"
                      placeholder={false}
                      required
                      // isDisabled={natureDropwdown}
                      onChange={(duration) => handleFormDuration(duration)}
                      value={formDuration}
                      onMenuOpen={(e) => handleDurationClick(e)}
                      noOptionsMessage={() => null}
                    />
                  </div>
                </div>
                <div className="flex w-full xl:flex-row flex-col xl:gap-10 gap-3">
                  <div className="flex flex-col gap-2 kvac-input xl:w-[50%] w-full mx-auto">
                    <label
                      htmlFor=""
                      className="lg:text-xl md:text-lg text-md font-medium"
                    >
                      End Date
                    </label>
                    <input
                      ref={endDate}
                      type="text"
                      name="endDate"
                      id="endDate"
                      disabled
                      className="p-2 select-container w-full bg-[#F5F5F5]"
                    />
                  </div>
                  <div className="flex flex-col gap-2 kvac-input xl:w-[50%] w-full mx-auto">
                    <label
                      htmlFor=""
                      className="lg:text-xl md:text-lg text-md font-medium"
                    >
                      Nature of Travel <span className="text-[#ef5399]">*</span>
                    </label>
                    <Select
                      options={NoT}
                      components={{ DropdownIndicator }}
                      id="nature_of_travel_form"
                      className="select-container w-full"
                      classNamePrefix="select-item"
                      placeholder={false}
                      required
                      // isDisabled={natureDropwdown}
                      onChange={(nature) => handleFormNature(nature)}
                      value={formNature}
                    />
                  </div>
                </div>
              </div>
              <div className="pt-2 flex justify-center captcha-container">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  className="g-recaptcha"
                  ref={(r) => setCaptchaRef(r) }
                  onChange={(token)=>{setCaptchaToken(token);}}
                  onClick={(e) => (e.executeAsync())}
                />
              </div>
              <div className="flex flex-row gap-4 m-2 checkbox-container mx-auto">
                <input
                  type="radio"
                  name=""
                  required
                  className="items-start w-5 h-full mt-1"
                />
                <input
                  type="checkbox"
                  id="agreement-form"
                  className="custom-radio lg:w-5 w-4 lg:h-5 h-4 "
                  ref={agreement}
                  onClick={handleCheck}
                  required
                ></input>
                <label
                  htmlFor=""
                  className="lg:text-xl md:text-sm text-[9px] font-medium agreement-text"
                >
                  In line with my use of the Safe to Go website, I hereby
                  authorize Safe to Go to process my personal and sensitive
                  information in accordance with the Privacy Policy given.
                </label>
              </div>
              <button
                className="mx-auto py-2 contact-submit px-8 lg:text-lg text-xs"
                id="submit_button"
                onClick={() => resetCaptcha()}
              >
                SUBMIT
              </button>
            </div>
          </form>
        </div>
        {insurance && <div className="blur-contact" id="blur_contact"></div>}
      </section>
      {insurance && (
        <Insurance
          close={setInsurance}
          blur={checkout}
          id={sessionId}
          insurance_package={insurance_package}
          urlCheckout={setUrl}
          ref={InsuranceModal}
          onClickApply={handleCheckoutOpen}
        />
      )}
      {checkout && (
        <Checkout
          close={handleCheckoutClose}
          insurance={setBlurInsurance}
          id={sessionId}
          url={url}
        />
      )}

      <NotifModal show={notif} dismiss={() => setNotif(false)} />
    </>
  );
}
