import { React, useState, useEffect, useMemo, useRef } from "react";
import SubscriptionForm1 from "../forms/SubscriptionForm2";
// import img from '../../img/services/_CX-Driven-Social-Media-Website-Digital-Services.jpg'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Fade, Bounce, AttentionSeeker } from "react-awesome-reveal";
import { BsGlobe2 } from "react-icons/bs";
import NotifModal from "../modal/NotifModal";
import Image from "../../img/contact/contact3.png";
import "./contact.css";
import "./insurance.scss";
import axios from "axios";
import Footer from "./../Footer";
import ReactHtmlParser from "react-html-parser";

import { InsurancePost } from "src/components/posts/InsurancePost";
// import { InsurancePosts } from "../posts/InsurancePost";

export default function ContactUs({
  close,
  blur,
  id,
  insurance_package,
  urlCheckout,
  onClickApply,
}) {
  console.log("insurance session id: " + id);
  const [notif, setNotif] = useState(false);
  const [firstSet, setFirstSet] = useState([]);
  const insurance_container = useRef();

  const bp1 = "bp1";
  const pp1 = "pp1";
  const bp2 = "bp2";
  const pp2 = "pp2";
  const bp3 = "bp3";

  useEffect(() => {
    setFirstSet(insurance_package);

    const blur = document.getElementById("blur_footer");
    insurance_package?.set2 == null
      ? blur.classList.add("d-none")
      : blur.classList.remove("d-none");
    insurance_package?.set2 == null
      ? (insurance_container.current.style.height = "calc(100% + 100px)")
      : (insurance_container.current.style.height = "530px");
  }, [insurance_package]);

  const handJoinSubmit = (e) => {
    const activeInsurance = document.getElementById("insurance_kvac");

    const formData = new FormData();
    formData.append("package_type", e);
    formData.append("session_id", id);
    // const apiSite = "https://api-staging.moodboard.space/api/email-store";
    // const apiSite = "https://kvac-api.binary.digital/api/update-form";
    const apiSite = `${process.env.REACT_APP_API_ENDPOINT}/api/update-form`;
    try {
      axios.post(apiSite, formData).then((response) => {
        if (response.status === 200) {
          console.log(response.data, "reponse data");
          urlCheckout(response.data.url);
          // console.log(url, "url out");
          // activeInsurance.style.height = "400px";

          onClickApply();

          // checkout(true);
        }
      });
    } catch (error) {
      console.error(error.response.data, "error axios");
    }
  };

  const handleClose = () => {
    document.body.style.overflowY = "auto";
    close(false);
    setFirstSet([]);
    const activeInsurance = document.getElementById("purchase");
    // activeInsurance.style.height = "auto";
  };

  const handleApply = (insurance) => {
    // checkout(true);
    console.log(insurance, "picked insurance");
    handJoinSubmit(insurance);
  };
  console.log(Object.keys(firstSet), "lenght of first set");

  var options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    // style: "currency",
    // currency: "PHP"
  };

  const formatter = new Intl.NumberFormat("en-US", options);

  return (
    <div className="modal" id="modalInsurancePackage">
      <AttentionSeeker effect="pulse">
        <div className="modal-container xl:px-[10px] lg:px-[7px] px-0 py-[30px]">
          <div className="modal-body relative z-20" id="insurance_kvac">
            <div className="absolute top-[3%] md:right-[3%] right-[3%] text-center md:max-w-[60px] max-w-[30px]">
              <button className="close-btn" onClick={handleClose}>
                <img
                  src="/image/contact/insurance-close.png"
                  className="object-contain"
                  alt=""
                />
              </button>
            </div>

            <div className="w-full flex flex-col items-center justify-center text-white">
              <div className="my-7 text-center lg:text-5xl md:text-3xl text-xl font-bold">
                <h5>INSURANCE PACKAGE</h5>
              </div>
              <div className="text-center md:mb-5 mb-3 lg:text-lg sm:text-sm text-xs lg:mx-[170px] md:mx-[80px] sm:mx-7  mx-5">
                <span className="italic">
                  Final premium rate is computed based on the details provided
                  in the application form from our insurance partner. Upon
                  completing the form, please check the indicated rate. Note
                  that the premium loading will be applied to insured
                  individuals or travelers aged 75 and above for all Short-Term
                  Travel Plans.
                </span>
              </div>
            </div>
            <div
              className="insurance-container grid md:grid-cols-2 xl:gap-10 lg:gap-6 gap-5 xl:px-[70px] lg:px-[20px] md:px-[10px] px-[5px] pb-[50px] mx-auto"
              ref={insurance_container}
            >
              {firstSet?.set1 && (
                <>
                  {/* basic package 1 */}
                  <div className="p-7 insurance-card">
                    <div className="insurance-title lg:text-xl md:text-lg text-sm">
                      BASIC PACKAGE 1
                    </div>
                    <div className="insurance-subtitle my-3 lg:text-3xl text-xl">
                      Up to PHP 1M insurance coverage
                    </div>
                    <div className="insurance-price lg:text-lg md:text-lg text-md">
                      {/* Starting at <b>PHP {insurance_package.set1.bp1.toFixed(2)}</b> */}
                      Starting at{" "}
                      <b>PHP {formatter.format(firstSet.set1.bp1)}</b>
                    </div>
                    <div className="insurance-list my-3 lg:text-md md:text-lg text-sm">
                      <span>
                        This package contains COVID-19 coverage and all the
                        essentials so you can travel without worry.
                      </span>
                      <br />
                      <br />
                      <span>
                        <b>This plan includes:</b>
                      </span>
                      <br />
                      <ul>
                        <li>Accidental death and disablement</li>
                        <li>Medical expenses including COVID-19</li>
                        <li>Personal baggage and delay</li>
                        <li>Personal Money and Document loss</li>
                        <li>Travel delay, reroute and cancellation</li>
                      </ul>
                    </div>
                    {/* <div className="insurance-footer lg:text-md md:text-lg text-sm">
                      <i>Valid worldwide excluding Schengen states.</i>
                    </div> */}
                    <br />
                    <div className="flex items-center justify-center">
                      <button
                        className="insurance-button py-2 px-10 align-middle"
                        onClick={() => handleApply(bp1)}
                      >
                        APPLY NOW
                      </button>
                    </div>
                  </div>
                  {/* end basic package 1 */}
                  {/* premium package 1 */}
                  <div className="p-7 insurance-card">
                    <div className="insurance-title lg:text-xl md:text-lg text-sm">
                      PREMIUM PACKAGE 1
                    </div>
                    <div className="insurance-subtitle my-3 lg:text-3xl md:text-xl text-md">
                      Up to PHP 2.5M insurance coverage
                    </div>
                    <div className="insurance-price lg:text-lg md:text-lg text-md">
                      {/* Starting at <b>PHP {insurance_package.set1.pp1.toFixed(2)}</b> */}
                      Starting at{" "}
                      <b>PHP {formatter.format(firstSet.set1.pp1)}</b>
                    </div>
                    <div className="insurance-list my-3 lg:text-md md:text-lg text-sm">
                      <span>
                        This premium package contains COVID-19 coverage and all
                        the essential coverage with greater benefits.
                      </span>
                      <br />
                      <br />
                      <span>
                        <b>This plan includes:</b>
                      </span>
                      <br />
                      <ul>
                        <li>Accidental death and disablement</li>
                        <li>Medical expenses including COVID-19</li>
                        <li>Personal baggage and delay</li>
                        <li>Personal Money and Document loss</li>
                        <li>Travel delay, reroute and cancellation</li>
                      </ul>
                    </div>
                    {/* <div className="insurance-footer lg:text-md md:text-lg text-sm">
                      <i>Valid worldwide excluding Schengen states.</i>
                    </div> */}
                    <br />
                    <div className="flex items-center justify-center">
                      <button
                        className="insurance-button py-2 px-10 align-middle"
                        onClick={() => handleApply(pp1)}
                      >
                        APPLY NOW
                      </button>
                    </div>
                  </div>
                  {/* end premium package 1 */}
                </>
              )}

              {firstSet.set2 !== null && (
                <>
                  {/* basic package 2 */}
                  <div className="p-7 insurance-card">
                    <div className="insurance-title lg:text-xl md:text-lg text-sm">
                      BASIC PACKAGE 2
                    </div>
                    <div className="insurance-subtitle my-3 lg:text-3xl md:text-xl text-md">
                      Up to PHP 250K insurance coverage
                    </div>
                    <div className="insurance-price lg:text-lg md:text-lg text-md">
                      {/* Starting at <b>PHP {insurance_package.set2.bp2.toFixed(2)}</b> */}
                      Starting at{" "}
                      <b>PHP {formatter.format(firstSet?.set2?.bp2)}</b>
                    </div>
                    <div className="insurance-list my-3 lg:text-md md:text-lg text-sm">
                      <span>
                        A budget-friendly plan that covers all your essential
                        travel needs.
                      </span>
                      <br />
                      <br />
                      <span>
                        <b>This plan includes:</b>
                      </span>
                      <br />
                      <ul>
                        <li>Personal Accident</li>
                        <li>Recovery of Travel Expenses</li>
                        <li>Travel Assistance Benefits</li>
                        <li>Medical Reimbursement</li>
                        <li>Travel Inconvenience Benefits</li>
                      </ul>
                    </div>
                    {/* <div className="insurance-footer lg:text-xl md:text-lg text-sm">
                  <i>Valid worldwide excluding Schengen states.</i>
                </div> */}
                    <br />
                    <div className="flex items-center justify-center">
                      <button
                        className="insurance-button py-2 px-10 align-middle"
                        onClick={() => handleApply(bp2)}
                      >
                        APPLY NOW
                      </button>
                    </div>
                  </div>
                  {/* premium package 2 */}
                  <div className="p-7 insurance-card">
                    <div className="insurance-title lg:text-xl md:text-lg text-sm">
                      PREMIUM PACKAGE 2
                    </div>
                    <div className="insurance-subtitle my-3 lg:text-3xl md:text-xl text-md">
                      Up to PHP 2.5M insurance coverage
                    </div>
                    <div className="insurance-price lg:text-lg md:text-lg text-md">
                      {/* Starting at <b>PHP {insurance_package.set2.pp2.toFixed(2)}</b> */}
                      Starting at{" "}
                      <b>PHP {formatter.format(firstSet?.set2?.pp2)}</b>
                    </div>
                    <div className="insurance-list my-3 lg:text-md md:text-lg text-sm">
                      <span>
                        This premium package contains all the essential coverage
                        with added COVID-19 coverage and other benefits.
                      </span>
                      <br />
                      <br />
                      <span>
                        <b>This plan includes:</b>
                      </span>
                      <br />
                      <ul>
                        <li>Extensive Personal Accident Insurance</li>
                        <li>COVID-19 Coverage</li>
                        <li>Recovery of Travel Expenses</li>
                        <li>Travel Assistance Benefits</li>
                        <li>Emergency Medical Treatment</li>
                        <li>Personal Liability</li>
                        <li>Travel Inconvenience Benefits</li>
                        <li>Value-added Benefits</li>
                      </ul>
                    </div>
                    {/* <div className="insurance-footer lg:text-xl md:text-lg text-sm">
                  <i>Valid worldwide excluding Schengen states.</i>
                </div> */}
                    <br />
                    <div className="flex items-center justify-center">
                      <button
                        className="insurance-button py-2 px-10 align-middle"
                        onClick={() => handleApply(pp2)}
                      >
                        APPLY NOW
                      </button>
                    </div>
                  </div>
                  {/* basic package 3 */}
                  <div className="p-7 insurance-card">
                    <div className="insurance-title lg:text-xl md:text-lg text-sm">
                      BASIC PACKAGE 3
                    </div>
                    <div className="insurance-subtitle my-3 lg:text-3xl md:text-xl text-md">
                      Up to PHP 500K insurance coverage
                    </div>
                    <div className="insurance-price lg:text-lg md:text-lg text-md">
                      {/* Starting at <b>PHP {insurance_package.set2.bp3.toFixed(2)}</b> */}
                      Starting at{" "}
                      <b>PHP {formatter.format(firstSet?.set2?.bp3)}</b>
                    </div>
                    <div className="insurance-list my-3 lg:text-md md:text-lg text-sm">
                      <span>
                        A complete plan that covers all your essential travel
                        needs and more.
                      </span>
                      <br />
                      <br />
                      <span>
                        <b>This plan includes:</b>
                      </span>
                      <br />
                      <ul>
                        <li>Personal Accident</li>
                        <li>Recovery of Travel Expenses</li>
                        <li>Travel Assistance Benefits</li>
                        <li>Medical Reimbursement</li>
                        <li>Travel Inconvenience Benefits</li>
                      </ul>
                    </div>
                    {/* <div className="insurance-footer lg:text-xl md:text-lg text-sm">
                  <i>Valid worldwide excluding Schengen states.</i>
                </div> */}
                    <br />
                    <div className="flex items-center justify-center">
                      <button
                        className="insurance-button py-2 px-10 align-middle"
                        onClick={() => handleApply(bp3)}
                      >
                        APPLY NOW
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="blur-contact-footer" id="blur_footer"></div>
            {blur && <div className="blur-contact" id="blur_contact"></div>}
          </div>
        </div>
      </AttentionSeeker>

      <NotifModal show={notif} dismiss={() => setNotif(false)} />
    </div>
  );
}
