import React, { useState, useEffect, useRef } from "react";
import {
  Fade,
  Bounce,
  Hinge,
  JackInTheBox,
  Roll,
  Rotate,
  Slide,
  Zoom,
} from "react-awesome-reveal";
import ReactHtmlParser from "react-html-parser";
import "./services.css";
import Vimeo from "src/components/vimeo";
import "./services.scss";

import { useMediaQuery } from "react-responsive";

const kvacitems = [
  {
    title:
      "Redefine experiences through <br class='md:block hidden' />transformative spaces",
    buttonText: "Start Growing My Brand",
    link: "#",
    src: "/image/carousel/carousel1-new.jpg",
    mobileSrc: "/image/carousel/mobile-kvac1-new.jpg",
  },
  {
    title:
      "Redefine experiences through <br class='md:block hidden' />transformative spaces",
    buttonText: "Start Transforming My Audience",
    link: "#",
    src: "/image/carousel/carousel2-new.jpg",
    mobileSrc: "/image/carousel/mobile-kvac2-new.jpg",
  },
  {
    title:
      "Redefine experiences through <br class='md:block hidden' />transformative spaces",
    buttonText: "Start Transforming My Audience",
    link: "#",
    src: "/image/carousel/carousel3-new.jpg",
    mobileSrc: "/image/carousel/mobile-kvac3-new.jpg",
  },
];



const LandingCarousel = () => {
  // ** Refs
  const carouselLink = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [showContent, setShowContent] = useState(false);
  const [carouselActive, setCarouselActive] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  let currIndex = activeIndex ? activeIndex : 0;

  const prev = () => {
    currIndex--;

    var carouselItems = document.querySelectorAll(
      ".carouselservice .__carousel-item"
    );
    if (currIndex < 0) {
      console.log("enter");
      currIndex = carouselItems.length - 1;
    }

    loadImage(currIndex);
  };
  const next = () => {
    currIndex++;

    var carouselItems = document.querySelectorAll(
      ".carouselservice .__carousel-item"
    );
    if (currIndex > carouselItems.length - 1) {
      currIndex = 0;
    }

    if (currIndex < 0) {
      currIndex = carouselItems.length - 1;
    }

    // console.log(currIndex);
    loadImage(currIndex);
  };

  const loadImage = (x) => {
    const image = new Image();
    image.onload = function () {};
    image.src = "";

    setActiveIndex(x);
    setShowContent(false);

    setTimeout(() => {}, 0);
  };

  const handleBlur = (e) => {
    setCarouselActive(false);
  };

  const handleFocus = (e) => {
    const currentTarget = e.currentTarget;
    if (currentTarget === document.activeElement) {
      setCarouselActive(true);
    }
  };

  

  useEffect(() => {
    // console.log(activeIndex);
    setTimeout(() => {
      setShowContent(true);
    }, 1000);
  }, [activeIndex]);

  useEffect(() => {
    const close = (e) => {
      if (carouselActive) {
        if (e.key === "ArrowRight") {
          next();
        } else if (e.key === "ArrowLeft") {
          prev();
        }
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [carouselActive]);

  useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, 3000);
    // console.log(activeIndex)
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="relative w-full">
        <div
          className="relative lg:mx-10 md:mx-6 mx-4 ipad-height-service banner-carousel overflow-hidden"
          id="carousel"
        >
          <div className="carouselservice">
            <div className="__carousel-inner">
              {kvacitems.map((e, i) => {
                return (
                  <div
                    className={`__carousel-item ${
                      activeIndex === i ? "active" : ""
                    }`}
                    key={e.title}
                  >
                    {/* <Slide delay={3} damping={0} direction="right" triggerOnce={true}> */}
                    <div className="group relative">
                      {/* <Fade delay={1}> */}
                      <div className="relative">
                        <div className="w-full ipad-height-service flex items-center">
                          {isDesktopOrLaptop && (
                            <img
                              src={e.src}
                              alt=""
                              className="w-auto h-full object-cover"
                            />
                          )}
                          {isTabletOrMobile && (
                            <img
                              src={e.mobileSrc}
                              alt=""
                              className="w-auto h-full object-cover"
                            />
                          )}
                        </div>
                        <div className="absolute w-full h-full z-10 text-white top-0 left-0 md:block hidden">
                          <div className="my-[3%] mx-[4%] flex flex-col lg:gap-10 gap-6 carousel-container-box">
                            <div className="lg:text-7xl md:text-4xl text-xl carousel-title">
                              <h5>
                                Easy. Safe. <br />
                                Personalized.
                              </h5>
                            </div>
                            <div className="lg:text-2xl md:text-md text-sm carousel-subtitle">
                              <span>
                                Tailor-made insurance packages that suit <br />{" "}
                                your needs. Take our 30-second assessment <br />{" "}
                                and find out which plan works best with your{" "}
                                <br /> travel details and budget.
                              </span>
                            </div>
                            <div>
                              <a
                                href="#purchase"
                                className="apply-button lg:py-4 py-2 lg:px-9 px-7 h-full lg:text-2xl md:text-lg text-md align-middle text-sarabun"
                              >
                                APPLY NOW
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </Fade> */}
                    </div>
                    {/* </Slide> */}
                  </div>
                );
              })}
            </div>

            <ol className="__carousel-indicator">
              <li
                className={activeIndex === 0 ? "active" : ""}
                data-slide-to="1"
                onClick={() => loadImage(0)}
              ></li>
              <li
                className={activeIndex === 1 ? "active" : ""}
                data-slide-to="2"
                onClick={() => loadImage(1)}
              ></li>
              <li
                className={activeIndex === 2 ? "active" : ""}
                data-slide-to="3"
                onClick={() => loadImage(2)}
              ></li>
            </ol>
          </div>
        </div>
        <div className="absolute -z-10 top-[50%]">
          <img
            src="image/about/kvacbg.png"
            alt=""
            className="object-cover w-full md:block hidden"
          />
          <img
            src="image/about/mobile-kvacbg.png"
            alt=""
            className="object-cover w-full md:hidden block"
          />
        </div>
      </div>
    </>
  );
};
export default LandingCarousel;
