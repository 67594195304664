export const PrimaryMenu = [
    {
        label: 'ABOUT US',
        path: '#about'
    },
    {
        label: 'PURCHASE',
        path: '#purchase'
    },
    {
        label: 'CONTACT US',
        path: '#contact'
    },
]

